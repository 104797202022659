import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { FaArrowAltCircleRight, FaPlay} from 'react-icons/fa';
import { CgCheckO } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import Data from "../../../../api/data.json";

const ServicesDetailsFaq = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen}
        videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} />

      <section className="tp-service-details-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="tp-faqs-left">
                <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title">Category...</h4>
                  <ul>
                    {
                      Data.dbServices.services.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/services/${index}`}>{item.title}
                            <i> <FaArrowAltCircleRight /> </i>
                            </Link>
                          </li>
                        )
                      }
                      )
                    }
                  </ul>
                </div>
                <div className="tp-faqs-left-img mb-30">
                  <a href="#"><img src={Data.defaultimg.img3} className="img" alt="img not found" /></a>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                {
                  Data.dbServices.services.map((item, index) => {
                    return(
                      <div key={index}>
                        <div className="tp-service-details-title mb-30">
                          <h3>{item.title}</h3>
                          <p>{item.description}</p>
                        </div>
                        <div className="tp-service-details-content mb-30">
                          <div className="tp-service-details-content-img mb-30">
                            <img src={item.image} className="img" alt="img not found" />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesDetailsFaq;