import React from "react";
import { Link } from "react-router-dom";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";

const ProjectsArea = () => {
  return (
    <>
      <div className="tp-prjects-area pt-120 pb-120">
        <h1 className="text-center text-black">Our Recent Projects</h1>
        <div className="container">
          <div class="elfsight-app-640c7298-b30d-4d35-af87-5b6385d30bdc"></div>
        </div>
      </div>
    </>
  );
};

export default ProjectsArea;
