import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Data from "../../../../api/data.json";

const imgUrl = require.context("../../../../img/", true);

const BannerArea = () => {
  return (
    <>
      <div className="brand-area pt-80 pb-50">
        <div className="container">
          <h1 className="text-center text-black">Find Us On</h1>
          <div className="brand-active swiper-container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div
                     className="brand-wrapper swiper-slide wow fadeInUp"
                     data-wow-delay="1.2s"
                     data-swiper-autoplay="5000"
                  >
                     <a href="https://www.startupwebsolutions.com.au/m-headquarters/m-m-contracting-services-llc-headquarters.html">
                     <img
                        src={imgUrl(`./Directorios/${Data.directorios[0].img}`)}
                        className="img-fluid"
                        alt="img"
                     />
                     </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                <div
                  className="brand-wrapper swiper-slide wow fadeInUp"
                  data-wow-delay="1.2s"
                  data-swiper-autoplay="5000"
                >
                  <a href="https://www.startupwebsolutions.com.au/m-headquarters/m-m-contracting-co-llc-headquarters.html">
                    <img
                      src={imgUrl(`./Directorios/${Data.directorios[1].img}`)}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                </div>
                <div className="col-lg-3 col-md-6">
                <div
                  className="brand-wrapper swiper-slide wow fadeInUp"
                  data-wow-delay="1.2s"
                  data-swiper-autoplay="5000"
                >
                  <a href="https://www.facebook.com/mmcontractingservicesllc/">
                    <img
                      src={imgUrl(`./Directorios/${Data.directorios[2].img}`)}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                </div>
                <div className="col-lg-3 col-md-6">
                <div
                  className="brand-wrapper swiper-slide wow fadeInUp"
                  data-wow-delay="1.2s"
                  data-swiper-autoplay="5000"
                >
                  <a href="https://twitter.com/MM_Contracting1">
                    <img
                      src={imgUrl(`./Directorios/${Data.directorios[3].img}`)}
                      className="img-fluid"
                      alt="img"
                    />
                  </a>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerArea;
