import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import AboutArea from '../../Home/Home/AboutArea/AboutArea';
import AboutBreadcrumb from './Breadcrumb/AboutBreadcrumb';
import WorkingProcess from './WorkingProcess/WorkingProcess';
import HomeTwoNavbar from '../../Home/HomeTwoNavbar/HomeTwoNavbar';
import AboutBlock from '../AboutBlock';

const About = () => {
    return (
        <>
            <HomeTwoNavbar/>
            <AboutBreadcrumb />
            <AboutArea />
            <WorkingProcess />
            <AboutBlock/>
            <Footer/>
        </>
    );
};

export default About;