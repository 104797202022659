import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import HomeTwoNavbar from '../../Home/HomeTwoNavbar/HomeTwoNavbar';
import ServicesDetailsBreadcrumb from './ServicesDetailsBreadcrumb/ServicesDetailsBreadcrumb';
import ServicesDetailsFaq from './ServicesDetailsFaq/ServicesDetailsFaq';

const ServicesDetails = () => {
    return (
        <>
            <HomeTwoNavbar/>
            <ServicesDetailsBreadcrumb />
            <ServicesDetailsFaq />
            <Footer />
        </>
    );
};

export default ServicesDetails;