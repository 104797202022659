import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

import  Data  from '../../../../api/data.json';
const imgUrl = require.context('../../../../img/', true);

const ContactBreadcrumb = () => {
   return (
      <>
         <Breadcrumb title="Contact" smText='Contact'
          bgimage={imgUrl(`./services/${Data.defaultimg.img6}`)}
         ></Breadcrumb>
      </>
   );
};

export default ContactBreadcrumb;