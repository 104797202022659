import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

import  Data  from '../../../../api/data.json';
const imgUrl = require.context('../../../../img/', true);

const ProjectsBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Gallery" smText=' Our Project'
            bgimage={imgUrl(`./services/${Data.defaultimg.img5}`)}
            ></Breadcrumb>
        </>
    );
};

export default ProjectsBreadcrumb;