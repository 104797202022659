import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import HomeTwoNavbar from '../../Home/HomeTwoNavbar/HomeTwoNavbar';
import ServicesArea from './ServicesArea/ServicesArea';
import ServicesBreadcrumb from './ServicesBreadcrumb/ServicesBreadcrumb';

const Services = () => {
    return (
        <>
            <HomeTwoNavbar/>
            <ServicesBreadcrumb />
            <ServicesArea />
            <Footer/>
        </>
    );
};

export default Services;