import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

import  Data  from '../../../../api/data.json';
const imgUrl = require.context('../../../../img/', true);

const ServicesBreadcrumb = () => {
    return (
        <>
            <Breadcrumb title="Services" smText='Services'
            bgimage={imgUrl(`./services/${Data.defaultimg.img3}`)}
            ></Breadcrumb>
        </>
    );
};

export default ServicesBreadcrumb;