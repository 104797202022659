import React from "react";
import Data from "../../../../api/data.json";

const ContactMap = () => {
  return (
    <>
      <div className="tp-map-area pt-60 pb-115">
        <div className="container">
          <div className="row">
            <h1 className="text-center">{Data.dbPrincipal.coverLocation}</h1>
            <div className="col-lg-6 col-md-6">
              <div className="tp-contact-map">
                <iframe
                  title="contact map"
                  src={Data.dbPrincipal.maps[0].iframe}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="tp-contact-map">
                <iframe
                  title="contact map"
                  src={Data.dbPrincipal.maps[1].iframe}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
