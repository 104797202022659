import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../../img/logo/M&M Contracting Services LLC - wh.png";

function ModalP() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="d-flex flex-column">
            <div className="text-end">
              <p className="fs-5" style={{cursor:"pointer"}} onClick={handleClose}>X</p>
            </div>
            <div className="text-center">
              <img src={Logo} width={"70%"} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4 className="text-center">Request An Estimate Now </h4>
            <form-contact
              to="mmcontractingservices@outlook.com"
              services="Remodeling Services, Painting Services,Tile Installation, Drywall Installation "
              server="https://paginasserver.herokuapp.com/email"
              img="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/LOGOS%2FM%26M%20Contracting%20Services%20LLC%20-%20wh.png?alt=media&token=63dbf40a-761e-4279-8cdc-a8fbd3f1272e"
              emailcolor="1"
            ></form-contact>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalP;
