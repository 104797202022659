import React from 'react';
import { Link } from 'react-router-dom';
import Clean from "../../img/icons/CLEAN.svg"
import IconLogo from "../../img/logo/logo.png"

const imgUrl = require.context('../../img/', true);

const ServicePageArea = ({service_image_num,ser_icon_img,ser_title,colorClass, ser_description,ser_itemservices, event_click}) => {
   return (
      <>
         <div className="col-lg-6">
            <div className="tp-service-three tp-service-four mb-30 wow fadeInUp" data-wow-delay="1.2s">
               <div className="tp-service-three-img">
                  <img src={imgUrl(`./services/${service_image_num}`)} className="img-fluid" alt="img-not-found"/>
                     <div className="tp-service-three-img-icon">
                        <img src={IconLogo} className="img-fluid mb-5" alt="img-not-found"/>
                     </div>
                     <div className="tp-service-three-img-overlay">
                        <div className="tp-service-three-img-overlay-icon">
                        <img src={IconLogo} className="img-fluid mb-5 p-2" alt="img-not-found"/>
                        </div>
                     </div>
               </div>
               <div className="tp-service-three-text fix">
                  <h4 className={ colorClass ? "home_three_title tp-service-three-title mb-20 heading-color-black-with-hover" : "tp-service-three-title mb-20 heading-color-black-with-hover"}>
                   <Link to="/services" onClick={event_click}>{ser_title}</Link></h4>
                  <p className="mb-30">{ser_description.substring(0, 100) + "..."}</p>
                  <ul>{ser_itemservices}</ul>
                  <div className="tp-service-three-text-btn">
                     <button className="yellow-btn service__btn" onClick={event_click}>
                        <i className="flaticon-enter"></i> More Info</button>
                        
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default ServicePageArea;