import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import  Data  from '../../../../api/data.json';

const imgUrl = require.context('../../../../img/', true);

const AboutBreadcrumb = () => {
   return (
      <>
         <Breadcrumb title="About"
         bgimage={imgUrl(`./services/${Data.defaultimg.img1}`)}
         ></Breadcrumb>
      </>
   );
};

export default AboutBreadcrumb;