import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home/Home';
import About from './pages/About/About/About';
import Services from './pages/Services/Services/Services';
import ServicesDetails from './pages/ServicesDetails/ServicesDetails/ServicesDetails';
import Projects from './pages/Projects/Projects/Projetcs';
import Contact from './pages/Contact/Contact/Contact';
import { WOW } from 'wowjs';
import ScrollToTop from './components/ScrollToTop';
import 'react-chat-widget/lib/styles.css';

const App = () => {
  // wow animation active
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} >
            <Route path="/services/:id" element={<ServicesDetails />} />
          </Route>
          <Route path="/servicesDetails" element={<ServicesDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;