import React from 'react';
import { Link } from 'react-router-dom';
import Data from "../../../../api/data.json"

const imgUrl = require.context('../../../../img/', true);

const ChoseArea = () => {
    return (
        <>
            <section className="tp-choose-area position-relative mt-120">
            <div className="tp-choose-yellow-bg"></div>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-6 col-lg-8">
                        <div className="tp-choose z-index">
                            <div className="tp-section-title-wrapper mt-60 wow fadeInUp" data-wow-delay=".2s">
                                <h5 className="tp-section-subtitle common-white-shape section__sm__title text-black">Why Choose {Data.dbPrincipal.companyName}</h5>
                                <h2 className="tp-section-title text-white">{Data.slogans.slogan3}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12">
                        <div className="tp-choose-img mb-30 z-index d-none d-xl-block wow fadeInUp" data-wow-delay=".6s">
                            <img src={imgUrl(`./services/${Data.defaultimg.img7}`)} alt="img not found" />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
    );
};

export default ChoseArea;